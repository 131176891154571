import PortfolioCategory from '@business/portfolio/PortfolioCategory';
import PortfolioCategoryList from '@components/portfolio/PortfolioCategoryList';
import { FunctionComponent } from 'react';

interface HomeProps
{
    portfolioCategories: PortfolioCategory[];
}

const Home: FunctionComponent<HomeProps> = (props) =>
{
    /** Render */
    return (
        <>
            <PortfolioCategoryList portfolioCategories={props.portfolioCategories} />
        </>
    );
}

export default Home;