import CustomHead, { HeadMetaData } from '@xFrame4/components/next/CustomHead';
import config from '@config';
import { useAppActionDispatch } from '@redux/hooks';
import { setAppLanguage } from '@redux/layoutSlice';
import { useTranslate } from '@xFrame4/components/Hooks';
import { FunctionComponent, useEffect } from 'react';
import Layout from '@components/layout/Layout';
import { createMenuItems, MenuItem } from '@components/menu/MenuBase';
import { GetStaticProps } from 'next';
import { tl } from '@xFrame4/common/Utils';
import Home from '@components/home/Home';
import AppSetting from '@xFrame4/business/AppSetting';
import PortfolioCategory from '@business/portfolio/PortfolioCategory';

/** Props that are common for each page. */
export interface PageProps
{
    menuItems: MenuItem[];
    appSettings: AppSetting[];
}

/**
 * Get the static props that are needed for each page.
 */
export async function getCommonStaticProps()
{
    // Menu items
    let menuItems = createMenuItems();

    // App settings
    let appSettings: any = null;
    let appSettingResult = await AppSetting.manager.load({ isPublic: true });
    if (appSettingResult != null) appSettings = appSettingResult.entities;

    return {
        menuItems: menuItems,
        appSettings: AppSetting.arrayToJson(appSettings)
    }
}

/** Props for portfolio category list pages. */
export interface PageWithPortfolioCategoriesProps 
{
    portfolioCategories: PortfolioCategory[];
}

/**
 * Get the portfolio categories to be used in getStaticProps().
 */
export async function getPortfolioCategoriesForStaticProps(language: string)
{
    let portfolioCategories: PortfolioCategory[] = [];
    let portfolioCategoriesResult = await PortfolioCategory.manager.load({ translation_Language: language });
    if (portfolioCategoriesResult != null) portfolioCategories = PortfolioCategory.arrayToJson(portfolioCategoriesResult.entities);

    return portfolioCategories;
}

interface HomePageProps extends PageProps, PageWithPortfolioCategoriesProps
{

}

export const getStaticProps: GetStaticProps<HomePageProps> = async (context) => 
{
    // Common page props
    let commonPageProps = await getCommonStaticProps();

    return {
        props: {
           ...commonPageProps,
           portfolioCategories: await getPortfolioCategoriesForStaticProps(config.defaultLanguage.code)
        }
    };
}

const HomePage: FunctionComponent<HomePageProps> = (props) =>
{
    const t = useTranslate();
    const dispatch = useAppActionDispatch();

    /** Set the global app language with Redux */
    useEffect(() => {
        dispatch(setAppLanguage(config.defaultLanguage))
    }, []);
    
    /** Render */
    return (
        <>
            <CustomHead 
                metaData={{
                    title: t('APP_TITLE'),
                    metaDescription: t('HOME_META_DESC')
                }}
            />

            <Layout 
                menuItems={props.menuItems}
                appSettings={AppSetting.createArrayFromJson(props.appSettings) as AppSetting[]}
            >
                <Home portfolioCategories={PortfolioCategory.createArrayFromJson(props.portfolioCategories) as PortfolioCategory[]} />
            </Layout>
        </>
    );
}

export default HomePage;